import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import "../styles/new-space.scss"
import SEOHeader from "../components/seo-header"
import SimpleMap from "../components/simplemap"
import scrollTo from "gatsby-plugin-smoothscroll"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import EnquiryForm from "../components/enquiry"
import NearBy from "../components/nearby"
import VizSensor from "react-visibility-sensor"
import moment from "moment"
import { nodeName } from "jquery"
const MeetingSpaceNew = props => {
  const [currentTab, setcurrentTab] = useState("overview")
  const [scrollPosition, setPosition] = useState({ scrollX: 0, scrollY: 0 })
  const [showPhotos, setshowPhotos] = useState(false)
  const [FAQ, setFAQ] = useState(0)

  console.log(props)

  
  useEffect(() => {
    function updatePosition() {
      setPosition({ scrollX: window.scrollX, scrollY: window.scrollY })
    }

    window.addEventListener("scroll", updatePosition)
    updatePosition()

    return () => window.removeEventListener("scroll", updatePosition)
  }, [])
  // console.log(scrollPosition)
  const post = props.data.listings
  //const center = { lat: post.location[0], lng: post.location[1] }
  var activeClass = ""

  const Photos = post.photos?.map((photo, index) => (
    <div className="CoworkingimageBox">
      <img
        src={photo?.replace(
          "https://firebasestorage.googleapis.com/v0/b/gofloaters.appspot.com/o",
          "https://cdn.app.gofloaters.com"
        )}
        className="d-block w-100 img-responsive"
        alt={post.spaceGFName + " " + post.spaceDisplayName}
      ></img>
    </div>
  ))

  const Photos2 = post.photos.map((photo, index) => (
    <div className="col-md-9">
      <img
        src={photo?.replace(
          "https://firebasestorage.googleapis.com/v0/b/gofloaters.appspot.com/o",
          "https://cdn.app.gofloaters.com"
        )}
        className="img-responsive"
        alt={post.spaceGFName + " " + post.spaceDisplayName}
      ></img>
    </div>
  ))

  const TimeFormat = time => {
    const t = new Date(time)
    const formatted = moment(t).format("MMMM d, YYYY")
    return formatted
  }

  const ratingText = rating => {
    if (rating > 4.5) {
      return "Very Good"
    } else if (rating >= 3.5 && rating <= 4.5) {
      return "Good"
    } else if (rating >= 2.5 && rating <= 3.5) {
      return "Average"
    } else if (rating < 2.5) {
      return "Below Average"
    }
  }

  const spaceFeedbackComp = post.spaceFeedback
    ?.sort((a, b) => (a.feedbackDate < b.feedbackDate ? 1 : -1))
    .map(dataDrop => {
      if (dataDrop.customerName) {
        return (
          <div className="ratingCard">
            <div className="row">
              <div className="col-md-12">
                <p className="customerName">{dataDrop.customerName}</p>
              </div>
              <div className="col-md-12">
                <p>{dataDrop.commentForSpaces}</p>
              </div>
              <div className="col-md-9 col-6">
                <p>
                  <div>
                    <span
                      class={
                        "stars-container stars-" +
                        Number(20) * Number(dataDrop.rating)
                      }
                    >
                      ★★★★★
                    </span>
                  </div>
                </p>
              </div>
              <div className="col-md-3 col-6 text-right">
                <p style={{ fontSize: "12px" }}>
                  {TimeFormat(dataDrop.feedbackDate)}
                </p>
              </div>
            </div>
          </div>
        )
      }
    })
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  let SpaceSuitedFor = ""
  if (post.purposesList) {
    SpaceSuitedFor = post.purposesList.map((purpose, index) => (
      <div className="SpaceSuitedForAlter" key={index}>
        <p>{purpose}</p>
      </div>
    ))
  }

  let operationTime = post.operationTime

  if (post.operationTime !== "") {
    operationTime = post.operationTime
      .split(", ")
      .map((operationTime, index) => (
        <div className="col-md-4  ">
          <div className="OperationTime row aligner" key={index}>
            <div className="col-2 text-center">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                {" "}
                <path d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
              </svg>{" "}
            </div>
            <div className="col-10">
              <p className="OperationTimeText">
                <span>{operationTime.split(":")[0]}</span>
                <br></br>
                {operationTime.split(" : ")[1]}
              </p>
            </div>
          </div>
        </div>
      ))
  }

  var Title = ""
  if (post.spaceType === "Cafe") {
    Title = post.spaceDisplayName + " - " + post.spaceAddress
  } else {
    Title =
      post.spaceGFName +
      " - " +
      post.OriginalName +
      " - " +
      post.spaceDisplayName +
      " | Meeting Space near " +
      post.spaceAddress +
      ", "
  }
  function tConv24(time24) {
    var ts = time24
    var H = +ts.substr(0, 2)
    var h = H % 12 || 12
    h = h < 10 ? "0" + h : h // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM"
    ts = h + ts.substr(2, 3) + ampm
    return ts
  }
  var today = new Date()
  var day = today.getDay()

  var daylist = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]
  var todayDay = daylist[day]
  function timeCal(todayDay) {
    switch (todayDay) {
      case "Monday":
        if (post.monday.holiday) return "Holiday"
        else {
          return tConv24(post.monday.from) + " - " + tConv24(post.monday.to)
        }
      case "Tuesday":
        if (post.tuesday.holiday) return "Holiday"
        else {
          return tConv24(post.tuesday.from) + " - " + tConv24(post.tuesday.to)
        }
      case "Wednesday":
        if (post.wednesday.holiday) return "Holiday"
        else {
          return (
            tConv24(post.wednesday.from) + " - " + tConv24(post.wednesday.to)
          )
        }
      case "Thursday":
        if (post.thursday.holiday) return "Holiday"
        else {
          return tConv24(post.thursday.from) + " - " + tConv24(post.thursday.to)
        }
      case "Friday":
        if (post.friday.holiday) return "Holiday"
        else {
          return tConv24(post.friday.from) + " - " + tConv24(post.friday.to)
        }
      case "Saturday":
        if (post.saturday.holiday) return "Holiday"
        else {
          return tConv24(post.saturday.from) + " - " + tConv24(post.saturday.to)
        }
      case "Sunday":
        if (post.sunday.holiday) return "Holiday"
        else {
          return tConv24(post.sunday.from) + " - " + tConv24(post.sunday.to)
        }
      default:
        return ""
    }
  }
  return (
    <div>
      <SEOHeader
        title={Title + "  " + post.spaceCity + " | GoFloaters"}
        description={Title + " : " + post.spaceDesc}
        socialURL={post.spaceImage}
        pinterest="true"
      ></SEOHeader>
      <Helmet>
        <script
          src="https://code.jquery.com/jquery-3.4.1.slim.min.js"
          integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n"
          crossorigin="anonymous"
          defer
        ></script>
        <script
          src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"
          integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo"
          crossorigin="anonymous"
          defer
        ></script>
        <script
          src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"
          integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6"
          crossorigin="anonymous"
          defer
        ></script>
        <script type="application/ld+json">{`{"@context":"http://schema.org","@type":"LocalBusiness","@id":"${
          post.slug
        }","name":"${
          post.spaceDisplayName + " - " + post.OriginalName
        }","telephone":"+91 7338 7308 00","url":"https://gofloaters.com/meeting-space/${
          post.slug
        }/","address":{"@type":"PostalAddress","addressCountry":"IN","streetAddress":"${
          post.spaceAddress
        }","addressLocality":"${
          post.spaceCity
        }","addressRegion":"IN"},"geo":{"@type":"GeoCoordinates","latitude":"${
          post.geoLocation[0]
        }","longitude":"${
          post.geoLocation[1]
        }"},"email":"support@gofloaters.com","image":{"@type":"ImageObject","url":"${
          post.photos[0]
        }"}}`}</script>
      </Helmet>
      <Layout>
        {scrollPosition.scrollY > 800 ? (
          <div className="topBarCoworking">
            <div className="container">
              <div className="row">
                <div className="col-md-8 DesktopOnly">
                  <br></br>
                  <br></br>
                  <ul className="coworkingLinks">
                    <li className={currentTab === "overview" ? "active" : ""}>
                      <a
                        onClick={() => {
                          scrollTo("#overview")
                          setcurrentTab("overview")
                        }}
                      >
                        Overview
                      </a>
                    </li>
                    <li className={currentTab === "amenities" ? "active" : ""}>
                      <a
                        onClick={() => {
                          scrollTo("#amenities")
                          setcurrentTab("amenities")
                        }}
                      >
                        Amenities
                      </a>
                    </li>
                    <li className={currentTab === "opening" ? "active" : ""}>
                      <a
                        onClick={() => {
                          scrollTo("#opening")
                          setcurrentTab("opening")
                        }}
                      >
                        Opening Hours
                      </a>
                    </li>
                    <li className={currentTab === "location" ? "active" : ""}>
                      <a
                        onClick={() => {
                          scrollTo("#location")
                          setcurrentTab("location")
                        }}
                      >
                        Location
                      </a>
                    </li>
                    <li className={currentTab === "rating" ? "active" : ""}>
                      <a
                        onClick={() => {
                          scrollTo("#rating")
                          setcurrentTab("rating")
                        }}
                      >
                        Reviews
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4 DesktopOnly">
                  {post.spaceType !== "Cafe" ? (
                    <h5 style={{ margin: "0", fontWeight: "bold" }}>
                      <br></br>
                      {post.OriginalName}
                    </h5>
                  ) : (
                    <h5 style={{ margin: "0", fontWeight: "bold" }}>
                      {post.spaceAddress}
                      <br></br>
                    </h5>
                  )}
                  {post.spaceType === "Cafe" ? (
                    <p>
                      {post.spaceDisplayName} in {post.spaceAddress},{" "}
                      {post.spaceCity}
                    </p>
                  ) : (
                    <p>
                      {post.spaceDisplayName} in {post.spaceAddress},{" "}
                      {post.spaceCity}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div
          itemscope
          itemtype="https://schema.org/LocalBusiness"
          className="container newspaceDetailContainer"
        >
          <div className="row">
            <div className="col-md-12 addressBar">
              <ol itemscope itemtype="https://schema.org/BreadcrumbList">
                <li
                  style={{ paddingLeft: "0" }}
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                >
                  <Link itemprop="item" to="/meeting-spaces/">
                    <span itemprop="name">Meeting Spaces</span>
                  </Link>
                  <meta itemprop="position" content="1" />
                </li>
                ›
                <li
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                >
                  <Link
                    to={
                      "/meeting-spaces/" +
                      post.spaceCity.toLowerCase().replace(" ", "-") +
                      "/all/"
                    }
                    itemscope
                    itemtype="https://schema.org/WebPage"
                    itemprop="item"
                    itemid={
                      "/meeting-spaces/" +
                      post.spaceCity.toLowerCase().replace(" ", "-") +
                      "/all/"
                    }
                  >
                    <span itemprop="name">{post.spaceCity}</span>
                  </Link>
                  <meta itemprop="position" content="2" />
                </li>
                ›
                <li
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                >
                  <span itemprop="name">
                    {post.spaceDisplayName} in {post.spaceAddress},{" "}
                    {post.spaceCity} ({post.spaceGFName})
                  </span>
                  <meta itemprop="position" content="3" />
                </li>
              </ol>
            </div>
          </div>
          <div className="row specialPadding">
            <div className="col-md-9 col-9">
              {post.spaceType === "Cafe" ? (
                <h1 className="coworkingTitle" itemprop="name">
                  {post.spaceDisplayName} in {post.spaceAddress},{" "}
                  {post.spaceCity}
                </h1>
              ) : (
                <h1 className="coworkingTitle" itemprop="name">
                  {post.spaceDisplayName} in {post.spaceAddress},{" "}
                  {post.spaceCity}
                </h1>
              )}
              {post.spaceType !== "Cafe" ? (
                <h2 className="coworkingLocationTitle">
                  {post.OriginalName}
                  <br></br>
                </h2>
              ) : (
                <h2 className="coworkingLocationTitle">
                  {post.spaceAddress}
                  <br></br>
                </h2>
              )}
            </div>
            <div className="col-md-3 col-3 text-right">
              <div className="MobileOnly">
                <a
                  className="shareButton"
                  href={
                    "https://wa.me/?text=Hey,\n\n Checkout this nice place for us on GoFloaters https://www.gofloaters.com/meeting-spaces/" +
                    post.slug
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 576 512"
                  >
                    <path d="M400 255.4V240 208c0-8.8-7.2-16-16-16H352 336 289.5c-50.9 0-93.9 33.5-108.3 79.6c-3.3-9.4-5.2-19.8-5.2-31.6c0-61.9 50.1-112 112-112h48 16 32c8.8 0 16-7.2 16-16V80 64.6L506 160 400 255.4zM336 240h16v48c0 17.7 14.3 32 32 32h3.7c7.9 0 15.5-2.9 21.4-8.2l139-125.1c7.6-6.8 11.9-16.5 11.9-26.7s-4.3-19.9-11.9-26.7L409.9 8.9C403.5 3.2 395.3 0 386.7 0C367.5 0 352 15.5 352 34.7V80H336 304 288c-88.4 0-160 71.6-160 160c0 60.4 34.6 99.1 63.9 120.9c5.9 4.4 11.5 8.1 16.7 11.2c4.4 2.7 8.5 4.9 11.9 6.6c3.4 1.7 6.2 3 8.2 3.9c2.2 1 4.6 1.4 7.1 1.4h2.5c9.8 0 17.8-8 17.8-17.8c0-7.8-5.3-14.7-11.6-19.5l0 0c-.4-.3-.7-.5-1.1-.8c-1.7-1.1-3.4-2.5-5-4.1c-.8-.8-1.7-1.6-2.5-2.6s-1.6-1.9-2.4-2.9c-1.8-2.5-3.5-5.3-5-8.5c-2.6-6-4.3-13.3-4.3-22.4c0-36.1 29.3-65.5 65.5-65.5H304h32zM72 32C32.2 32 0 64.2 0 104V440c0 39.8 32.2 72 72 72H408c39.8 0 72-32.2 72-72V376c0-13.3-10.7-24-24-24s-24 10.7-24 24v64c0 13.3-10.7 24-24 24H72c-13.3 0-24-10.7-24-24V104c0-13.3 10.7-24 24-24h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H72z" />
                  </svg>{" "}
                </a>
              </div>
              <div className="DesktopOnly">
                <a
                  className="shareButton"
                  href={
                    "https://wa.me/?text=Hey,\n\n Checkout this nice place for us on GoFloaters https://www.gofloaters.com/meeting-spaces/" +
                    post.slug
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 576 512"
                  >
                    <path d="M400 255.4V240 208c0-8.8-7.2-16-16-16H352 336 289.5c-50.9 0-93.9 33.5-108.3 79.6c-3.3-9.4-5.2-19.8-5.2-31.6c0-61.9 50.1-112 112-112h48 16 32c8.8 0 16-7.2 16-16V80 64.6L506 160 400 255.4zM336 240h16v48c0 17.7 14.3 32 32 32h3.7c7.9 0 15.5-2.9 21.4-8.2l139-125.1c7.6-6.8 11.9-16.5 11.9-26.7s-4.3-19.9-11.9-26.7L409.9 8.9C403.5 3.2 395.3 0 386.7 0C367.5 0 352 15.5 352 34.7V80H336 304 288c-88.4 0-160 71.6-160 160c0 60.4 34.6 99.1 63.9 120.9c5.9 4.4 11.5 8.1 16.7 11.2c4.4 2.7 8.5 4.9 11.9 6.6c3.4 1.7 6.2 3 8.2 3.9c2.2 1 4.6 1.4 7.1 1.4h2.5c9.8 0 17.8-8 17.8-17.8c0-7.8-5.3-14.7-11.6-19.5l0 0c-.4-.3-.7-.5-1.1-.8c-1.7-1.1-3.4-2.5-5-4.1c-.8-.8-1.7-1.6-2.5-2.6s-1.6-1.9-2.4-2.9c-1.8-2.5-3.5-5.3-5-8.5c-2.6-6-4.3-13.3-4.3-22.4c0-36.1 29.3-65.5 65.5-65.5H304h32zM72 32C32.2 32 0 64.2 0 104V440c0 39.8 32.2 72 72 72H408c39.8 0 72-32.2 72-72V376c0-13.3-10.7-24-24-24s-24 10.7-24 24v64c0 13.3-10.7 24-24 24H72c-13.3 0-24-10.7-24-24V104c0-13.3 10.7-24 24-24h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H72z" />
                  </svg>{" "}
                  Share
                </a>
                {post.Rating ? (
                  <p className="DesktopOnly">
                    <div
                      itemprop="aggregateRating"
                      itemscope
                      itemtype="https://schema.org/AggregateRating"
                      className="aggregateRating"
                    >
                      <b>Rated:</b>{" "}
                      <span itemprop="ratingValue">{post.Rating} / 5</span>{" "}
                      {/* <span
                        classname={
                          "stars-container stars-" +
                          Number(20) * Number(post.Rating)
                        }
                      >
                        ★★★★★
                      </span> */}
                      <a onClick={() => scrollTo("#rating")}>
                        See all{" "}
                        <span itemprop="reviewCount">
                          {post.spaceFeedback.length}
                        </span>{" "}
                        review
                        {post.spaceFeedback.length > 1 ? "s" : ""}
                      </a>
                    </div>
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {showPhotos ? (
            <div
              className="modal fade show "
              id="weAreClosed"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="weAreClosed"
              aria-hidden="true"
              style={{ display: "block" }}
            >
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content" style={{ background: "#fff" }}>
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setshowPhotos(false)
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body" style={{ padding: 15 }}>
                    <Carousel
                      swipeable={true}
                      draggable={true}
                      showDots={true}
                      responsive={responsive}
                      ssr={true} // means to render carousel on server-side.
                      autoPlay={false}
                      autoPlaySpeed={3000}
                      keyBoardControl={true}
                      infinite={true}
                      containerClassName="carousel-container"
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                      deviceType={props.deviceType}
                      dotListClassName="custom-dot-list-style"
                    >
                      {Photos}
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="container DesktopOnly">
            <div className="row noPadding">
              <div className="col-md-8 noPadding">
                <img
                  data-src={post.photos[0]}
                  alt={post.spaceDisplayName}
                  className="lazyload img-responsive"
                ></img>

                <div
                  className="viewAllPhotos"
                  onClick={() => {
                    setshowPhotos(true)
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 16L8.586 11.414C8.96106 11.0391 9.46967 10.8284 10 10.8284C10.5303 10.8284 11.0389 11.0391 11.414 11.414L16 16M14 14L15.586 12.414C15.9611 12.0391 16.4697 11.8284 17 11.8284C17.5303 11.8284 18.0389 12.0391 18.414 12.414L20 14M14 8H14.01M6 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V6C20 5.46957 19.7893 4.96086 19.4142 4.58579C19.0391 4.21071 18.5304 4 18 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20Z"
                      stroke="#2193B0"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  View all photos
                </div>
              </div>
              <div className="col-md-4  noPadding">
                {post.photos[1] ? (
                  <img
                    data-src={post.photos[1]}
                    alt={post.spaceDisplayName}
                    className="lazyload img-responsive"
                    style={{ marginBottom: "5px" }}
                  ></img>
                ) : (
                  <img
                    data-src="https://assets.gofloaters.com/updates/common.png"
                    className="lazyload img-responsive "
                    style={{ marginBottom: "5px" }}
                  ></img>
                )}
                {post.photos[2] ? (
                  <img
                    data-src={post.photos[2]}
                    alt={post.spaceDisplayName}
                    className="lazyload img-responsive"
                    style={{ marginBottom: "5px" }}
                  ></img>
                ) : (
                  <img
                    data-src="https://assets.gofloaters.com/updates/meeting-spaces%20-placeholder.png"
                    className="lazyload img-responsive"
                    style={{ marginBottom: "5px" }}
                  ></img>
                )}
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-12 MobileOnly">
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                autoPlay={false}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                containerClassName="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType={props.deviceType}
                dotListClassName="custom-dot-list-style"
              >
                {Photos}
              </Carousel>
            </div>

            <div className="col-md-12  ">
              <br />
              <div className="row">
                <div className="col-md-8">
                  <div className="row aligner paddingTrigger">
                    <div className="col-md-1 col-3 text-center">
                      <img
                        src="https://assets.gofloaters.com/icons/svg/lower-price.svg"
                        style={{ width: "36px" }}
                      ></img>
                    </div>
                    <div className="col-md-11 col-9">
                      <h2 style={{ fontWeight: "bold", fontSize: "1.6em" }}>
                        Lowest Price Guaranteed
                      </h2>
                      <p>
                        You can trust us with our fair and dependable prices.
                      </p>
                    </div>
                  </div>
                  <div className="row aligner paddingTrigger">
                    <div className="col-md-1 col-3 text-center">
                      <img
                        src="https://assets.gofloaters.com/icons/svg/free-rescheduling.svg"
                        style={{ width: "36px" }}
                      ></img>
                    </div>
                    <div className="col-md-11 col-9">
                      <h2 style={{ fontWeight: "bold", fontSize: "1.6em" }}>
                        Free Rescheduling
                      </h2>
                      <p>
                        You can modify the date,time and other details of your
                        scheduled booking .
                      </p>
                    </div>
                  </div>
                  <div className="row aligner paddingTrigger">
                    <div className="col-md-1 col-3 text-center">
                      <img
                        src="https://assets.gofloaters.com/icons/svg/free-cancellation.svg"
                        style={{ width: "36px" }}
                      ></img>
                    </div>
                    <div className="col-md-11 col-9">
                      <h2 style={{ fontWeight: "bold", fontSize: "1.6em" }}>
                        Free Cancellation
                      </h2>
                      <p>You can cancel booking upto 48 hours</p>
                    </div>
                  </div>
                  <hr />
                  {post.spaceDesc ? (
                    <div id="overview">
                      <br></br>
                      <h4>About this space</h4>{" "}
                      <VizSensor
                        onChange={() => {
                          setcurrentTab("overview")
                        }}
                      >
                        <p itemprop="description">{post.spaceDesc}</p>
                      </VizSensor>
                    </div>
                  ) : (
                    ""
                  )}
                  {SpaceSuitedFor !== "" ? (
                    <div>
                      <br></br>
                      <h4>Space Best Suited for</h4>
                      {SpaceSuitedFor}
                    </div>
                  ) : (
                    ""
                  )}
                  <div>
                    <br></br>
                    {post.exclusiveOffer ? (
                      <div>
                        <h4>Remarks</h4> <p>{post.exclusiveOffer}</p>
                        <br></br>
                      </div>
                    ) : (
                      <p> </p>
                    )}
                    <div className="row amenties">
                      <div className="col-md-12">
                        <h4 id="amenities">Amenities</h4>
                        <VizSensor
                          onChange={() => {
                            setcurrentTab("amenities")
                          }}
                        >
                          <p>Space info</p>
                        </VizSensor>
                      </div>
                      {post.Facility.search("Hi Speed WiFi") > 1 ? (
                        <div className="col-md-6 col-12">
                          <div className="row aligner">
                            <div className="col-3 text-center">
                              <img
                                src="https://assets.gofloaters.com/icons/svg/hi_speed_wifi.svg"
                                alt="Hi Speed WiFi"
                                style={{
                                  width: "45px",
                                  border: "1px solid #2193B0",
                                  padding: "10px 7px",
                                  borderRadius: "5px",
                                }}
                              ></img>
                            </div>
                            <div className="col-9">
                              <p>Hi Speed WiFi</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {post.Facility.search("Power Backup") > 1 ? (
                        <div className="col-md-6 col-12">
                          <div className="row aligner">
                            <div className="col-3 text-center">
                              <img
                                src="https://assets.gofloaters.com/icons/svg/power_backup.svg"
                                alt="Power Backup"
                                style={{
                                  width: "45px",
                                  border: "1px solid #2193B0",
                                  padding: "10px 7px",
                                  borderRadius: "5px",
                                }}
                              ></img>
                            </div>
                            <div className="col-9">
                              <p>Power Backup</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {post.Facility.search("WiFi Backup") > 1 ? (
                        <div className="col-md-6 col-12">
                          <div className="row aligner">
                            <div className="col-3 text-center">
                              <img
                                src="https://assets.gofloaters.com/icons/svg/wifi_backup.svg"
                                alt="WiFi Backup"
                                style={{
                                  width: "45px",
                                  border: "1px solid #2193B0",
                                  padding: "10px 7px",
                                  borderRadius: "5px",
                                }}
                              ></img>
                            </div>
                            <div className="col-9">
                              <p> WiFi Backup</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {post.Facility.search("Projector") > 1 ? (
                        <div className="col-md-6 col-12">
                          <div className="row aligner">
                            <div className="col-3 text-center">
                              <img
                                src="https://assets.gofloaters.com/icons/svg/projectors.svg"
                                alt="Projector"
                                style={{
                                  width: "45px",
                                  border: "1px solid #2193B0",
                                  padding: "10px 7px",
                                  borderRadius: "5px",
                                }}
                              ></img>
                            </div>
                            <div className="col-9">
                              <p>Projector</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {post.Facility.search("Television") > 1 ? (
                        <div className="col-md-6 col-12">
                          <div className="row aligner">
                            <div className="col-3 text-center">
                              <img
                                src="https://assets.gofloaters.com/icons/svg/television.svg"
                                alt="Television"
                                style={{
                                  width: "45px",
                                  border: "1px solid #2193B0",
                                  padding: "10px 7px",
                                  borderRadius: "5px",
                                }}
                              ></img>
                            </div>
                            <div className="col-9">
                              <p>Television</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {post.Facility.search("Whiteboard") > 1 ? (
                        <div className="col-md-6 col-12">
                          <div className="row aligner">
                            <div className="col-3 text-center">
                              <img
                                src="https://assets.gofloaters.com/icons/svg/white_board.svg"
                                alt="Whiteboard"
                                style={{
                                  width: "45px",
                                  border: "1px solid #2193B0",
                                  padding: "10px 7px",
                                  borderRadius: "5px",
                                }}
                              ></img>
                            </div>
                            <div className="col-9">
                              <p>Whiteboard</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>{" "}
                  <br></br>
                  <p>Building Info</p>{" "}
                  <div className="row amenties">
                    {post.Facility.search("AC") > 1 ? (
                      <div className="col-md-6 col-12">
                        <div className="row aligner">
                          <div className="col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/ac.svg"
                              alt="AC"
                              style={{
                                width: "45px",
                                border: "1px solid #2193B0",
                                padding: "10px 7px",
                                borderRadius: "5px",
                              }}
                            ></img>
                          </div>
                          <div className="col-9">
                            <p>AC</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {post.Facility.search("Metro Nearby") > 1 ? (
                      <div className="col-md-6 col-12">
                        <div className="row aligner">
                          <div className="col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/metro_nearby.svg"
                              alt="Metro Nearby"
                              style={{
                                width: "45px",
                                border: "1px solid #2193B0",
                                padding: "10px 7px",
                                borderRadius: "5px",
                              }}
                            ></img>
                          </div>
                          <div className="col-9">
                            <p>Metro Nearby</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {post.Facility.search("Free Parking") > 1 ? (
                      <div className="col-md-6 col-12">
                        <div className="row aligner">
                          <div className="col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/free_parking.svg"
                              alt="Free Parking"
                              style={{
                                width: "45px",
                                border: "1px solid #2193B0",
                                padding: "10px 7px",
                                borderRadius: "5px",
                              }}
                            ></img>
                          </div>
                          <div className="col-9">
                            <p>Free Parking</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {post.Facility.search("Rest Rooms") > 1 ? (
                      <div className="col-md-6 col-12">
                        <div className="row aligner">
                          <div className="col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/rest_rooms.svg"
                              alt="Rest Rooms"
                              style={{
                                width: "45px",
                                border: "1px solid #2193B0",
                                padding: "10px 7px",
                                borderRadius: "5px",
                              }}
                            ></img>
                          </div>
                          <div className="col-9">
                            <p>Rest Rooms</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {post.Facility.search("Coffee/Tea") > 1 ? (
                      <div className="col-md-6 col-12">
                        <div className="row aligner">
                          <div className="col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/coffee_tea.svg"
                              alt="Coffee/Tea"
                              style={{
                                width: "45px",
                                border: "1px solid #2193B0",
                                padding: "10px 7px",
                                borderRadius: "5px",
                              }}
                            ></img>
                          </div>
                          <div className="col-9">
                            <p>Coffee/Tea</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {post.Facility.search("Pantry") > 1 ? (
                      <div className="col-md-6 col-12">
                        <div className="row aligner">
                          <div className="col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/pantry.svg"
                              alt="Pantry"
                              style={{
                                width: "45px",
                                border: "1px solid #2193B0",
                                padding: "10px 7px",
                                borderRadius: "5px",
                              }}
                            ></img>
                          </div>
                          <div className="col-9">
                            <p>Pantry</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {post.Facility.search("Meeting Rooms") > 1 ? (
                      <div className="col-md-6 col-12">
                        <div className="row aligner">
                          <div className="col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/meeting_rooms.svg"
                              alt="Meeting Rooms"
                              style={{
                                width: "45px",
                                border: "1px solid #2193B0",
                                padding: "10px 7px",
                                borderRadius: "5px",
                              }}
                            ></img>
                          </div>
                          <div className="col-9">
                            <p>Meeting Rooms</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {post.Facility.search("Outside Food Allowed") > 1 ? (
                      <div className="col-md-6 col-12">
                        <div className="row aligner">
                          <div className="col-3 text-center">
                            <img
                              src="https://assets.gofloaters.com/icons/svg/outside_food_allowed.svg"
                              alt="Outside Food Allowed"
                              style={{
                                width: "45px",
                                border: "1px solid #2193B0",
                                padding: "10px 7px",
                                borderRadius: "5px",
                              }}
                            ></img>
                          </div>
                          <div className="col-9">
                            <p>Outside Food Allowed</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>{" "}
                  <div>
                    <br></br>
                    <h4 id="opening">Opening Hours</h4>
                    <VizSensor
                      onChange={() => {
                        setcurrentTab("opening")
                      }}
                    >
                      <b>Today ({todayDay})</b>
                    </VizSensor>
                    <br />
                    {timeCal(todayDay)}
                    <br />
                    <br />
                    <div
                      className="row"
                      style={{
                        padding: "10px 5px",
                        margin: "0",
                        border: "1px solid #D9D9D9",
                      }}
                    >
                      {operationTime}
                    </div>
                  </div>
                  <br></br>
                  <h4>Location</h4>
                  <VizSensor
                    onChange={() => {
                      setcurrentTab("location")
                    }}
                  >
                    {post.geoLocation !== "" ? (
                      <SimpleMap
                        lat={post.geoLocation[0]}
                        lng={post.geoLocation[1]}
                      ></SimpleMap>
                    ) : (
                      "<p></p>"
                    )}
                  </VizSensor>
                  <br></br>
                  <br></br>
                  <a
                    href={
                      "https://maps.google.com/?q=" +
                      post.geoLocation[0] +
                      "," +
                      post.geoLocation[1]
                    }
                    target="_blank"
                  >
                    View on map <i className="fa fa-chevron-right"></i>
                  </a>
                  <br></br>{" "}
                  {post.nearBy.length > 1 ? <h4>Whats’s Nearby</h4> : ""}
                  <div className="row">
                    {post.nearBy
                      ? post.nearBy.map(near => (
                          <div className="col-md-6 col-6">
                            <div className="row">
                              <div className="col-6">{near.name}</div>
                              <div className="col-6">{near.distance} km</div>
                            </div>
                          </div>
                        ))
                      : ""}
                  </div>
                  <div className="row" id="rating">
                    <VizSensor
                      onChange={() => {
                        setcurrentTab("rating")
                      }}
                    >
                      <div className="col-md-8">
                        <br></br>
                        <h4>Reviews</h4>
                        <p className="customerRatingTime">
                          {post.spaceFeedback.length} reviews
                        </p>
                        {post.spaceFeedback.length > 0
                          ? spaceFeedbackComp
                          : "No Review"}
                      </div>
                    </VizSensor>
                  </div>
                  <br></br>
                  <h4>Space FAQ's</h4>
                  <div className="accordion" id="accordionExample">
                    {post.faqs.map((faq, index) => (
                      <div className="accordion-item">
                        <div
                          className="accordion-header"
                          id={"heading_" + index}
                        >
                          <h2 className="mb-0 alignerFaq">
                            <button
                              className="btn btn-link"
                              data-toggle="collapse"
                              data-target={"#collapse_" + index}
                              aria-expanded={index === 0 ? "true" : ""}
                              aria-controls={"collapse_" + index}
                              onClick={() =>
                                index != FAQ ? setFAQ(index) : setFAQ(-1)
                              }
                            >
                              {faq.question}
                            </button>{" "}
                            <i
                              className={
                                FAQ != index
                                  ? "fa fa-chevron-right"
                                  : "fa fa-chevron-down"
                              }
                            ></i>
                          </h2>
                        </div>

                        <div
                          id={"collapse_" + index}
                          className={
                            index === 0
                              ? "accordion-collapse collapse show"
                              : "accordion-collapse collapse"
                          }
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">{faq.answer}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <br></br>
                  <h4>Space Overview</h4>
                  <p
                    className="spaceOverviewtext"
                    dangerouslySetInnerHTML={{ __html: post.spaceOverview }}
                  ></p>
                  <br></br> <br></br>
                  <div></div>
                </div>
                <div className="col-md-4 DesktopOnly">
                  <div className="priceBarItem sticky">
                    <div>
                      {post.Rating ? (
                        <p>
                          <div
                            itemprop="aggregateRating"
                            itemscope
                            itemtype="https://schema.org/AggregateRating"
                            className="aggregateRating"
                          >
                            <b>Rated:</b>{" "}
                            <span itemprop="ratingValue">
                              {post.Rating} / 5
                            </span>{" "}
                            <a onClick={() => scrollTo("#rating")}>
                              See all{" "}
                              <span itemprop="reviewCount">
                                {post.spaceFeedback.length}
                              </span>{" "}
                              review
                              {post.spaceFeedback.length > 1 ? "s" : ""}
                            </a>
                          </div>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="priceCloumn row">
                      {post.hourPassAvailable && post.priceHr !== " " ? (
                        <div className="PriceTagnew col-6">
                          <p>
                            {post.priceperhrPreDiscount ? (
                              <b className="priceStrike">
                                ₹ {post.priceperhrPreDiscount}
                                <br />
                              </b>
                            ) : (
                              ""
                            )}

                            <b>₹ {post.priceHr}</b>
                            <span>/hour</span>
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      {post.dayPassAvailable && post.priceDay !== " " ? (
                        <div className="PriceTagnew col-6">
                          <p>
                            {post.priceperdayPreDiscount ? (
                              <b className="priceStrike">
                                ₹ {post.priceperdayPreDiscount} <br />
                              </b>
                            ) : (
                              ""
                            )}

                            <b>₹ {post.priceDay}</b>
                            <span>/day</span>
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="spaceButtonAlter">
                      {post.online ? (
                        <Link to={"/booking?spaceid=" + post.spaceId}>
                          Book Now
                        </Link>
                      ) : (
                        <a
                          href={
                            "https://www.gofloaters.com/app/#/home/explore/bookingrequest/" +
                            post.spaceId
                          }
                          target="_blank"
                          className="disabled"
                        >
                          Currently unavailable
                        </a>
                      )}
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#878787",
                          paddingTop: "10px",
                        }}
                      >
                        You won’t be charged yet
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="MobileOnly FloatButtonBottom">
                <div className="container">
                  <div className="row aligner">
                    <div className="col-6">
                      <div className="PriceTagnew">
                        {post.dayPassAvailable && post.priceDay !== " " ? (
                          <p>
                            {post.priceperdayPreDiscount ? (
                              <b className="priceStrike">
                                ₹ {post.priceperdayPreDiscount} <br />
                              </b>
                            ) : (
                              ""
                            )}
                            <b>₹ {post.priceDay}</b> <span>/ Day</span>
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="PriceTagnew">
                        {post.hourPassAvailable && post.priceHr !== " " ? (
                          <p>
                            {post.priceperhrPreDiscount ? (
                              <b className="priceStrike">
                                ₹ {post.priceperhrPreDiscount} <br />
                              </b>
                            ) : (
                              ""
                            )}
                            <b>₹ {post.priceHr}</b> <span>/ Hours</span>
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-6 text-right">
                      <div className="spaceButtonAlter">
                        {post.online ? (
                          <Link to={"/booking?spaceid=" + post.spaceId}>
                            Book Now
                          </Link>
                        ) : (
                          // <a data-toggle="modal" data-target="#exampleModal">
                          //   Enquire Now
                          // </a>
                          <a
                            href={
                              "https://www.gofloaters.com/app/#/home/explore/bookingrequest/" +
                              post.spaceId
                            }
                            target="_blank"
                            className="disabled"
                          >
                            Currently unavailable
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8"></div>
          </div>
        </div>
        <br></br>

       
      </Layout>
      {/* <div
          className="modal fade show"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <EnquiryForm spaceId={post.spaceId} />
          </div>
        </div> */}
    </div>
  )
}

export default MeetingSpaceNew
export const query = graphql`
  query MeetingSpaceNew($slug: String!) {
    listings(slug: { eq: $slug }) {
      spaceDisplayName
      OriginalName
      spaceCity
      spaceGFName
      spaceId
      spaceImage
      spaceTitle
      spaceType
      subType
      monthPassAvailable
      officeSpaceType
      priceDay
      priceHr
      priceMonth
      photos
      Facility
      operationTime
      hourPassAvailable
      exclusiveOffer
      dayPassAvailable
      purposesList
      spaceAddress
      noiseLevel
      landmark
      spaceDesc
      hasCovidSafeBadge
      securitydeposit
      seat
      slug
      geoLocation
      spaceOverview
      Rating
      priceperdayPreDiscount
      priceperhrPreDiscount
      spaceFeedback {
        customerName
        feedbackDate
        gofloaterrating
        rating
        spaceId
        spaceName
        commentForSpaces
        commentForFloaters
      }
      online
      monday {
        from
        to
        useNow
        holiday
      }
      tuesday {
        from
        to
        useNow
        holiday
      }
      wednesday {
        from
        to
        useNow
        holiday
      }
      thursday {
        from
        to
        useNow
        holiday
      }
      friday {
        from
        to
        useNow
        holiday
      }
      saturday {
        from
        to
        useNow
        holiday
      }
      sunday {
        from
        to
        useNow
        holiday
      }
      nearBy {
        distance
        name
        type
      }
      faqs {
        answer
        question
      }
    }
  }
`
